import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';

const Upload = (props) => {
  return (
    <span class={`${props.masterClasses || ''}`} title={props.title || ''}>
      <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20"><path d="M5 16q-1.667 0-2.833-1.177Q1 13.646 1 11.979q0-1.583 1.073-2.739Q3.146 8.083 4.729 8q.479-1.792 1.948-2.896Q8.146 4 10 4q2.229 0 3.865 1.427Q15.5 6.854 15.5 9q1.458 0 2.479 1.021Q19 11.042 19 12.479q0 1.459-1.021 2.49Q16.958 16 15.5 16h-4.75q-.625 0-1.062-.438-.438-.437-.438-1.062v-3.625l-1.188 1.187L7 11l3-3 3 3-1.062 1.062-1.188-1.187V14.5h4.75q.833 0 1.417-.583.583-.584.583-1.417 0-.833-.583-1.417-.584-.583-1.417-.583H14V9q0-1.521-1.198-2.51Q11.604 5.5 10 5.5q-1.625 0-2.75 1.177T5.896 9.5H5q-1.042 0-1.771.729Q2.5 10.958 2.5 12q0 1.042.729 1.771.729.729 1.771.729h2.75V16Zm5-5.125Z"/></svg></span>
  );
};

export default Upload;
