/*
  Modified By: Komal Wable
  Modified On: 19 March 2023
  Modification: Make UI correct
*/
/*
  BY: Prasanndatta Kawadkar
  ON: 08 Feb 2024
  Modification: Cells were not aligned properly, fixed that
*/
import { h, Component } from 'preact';
import CONSTANTS from '../../lib/constants';
import http from 'fetch-bb';
import { useState, useEffect } from "preact/hooks";
import { startLoader, stopLoader,formatDateTime, getFormattedAmount} from '../../lib/utils';
import {Toast} from '../../lib/toastr';
//export default class Bill extends Component {
const Bill = (props) => {

  let [ branchList, setBranchList ] = useState([]);
  let [ loadingBranchList, setLoadingBranchList ] = useState(false);


  useEffect(() => {
    getBranchList();
  }, []);


  async function getBranchList() {
    try {
      let response = await http.get(`${CONSTANTS.API_URL}/api/v1/branch`,{uuids:props.bill.branchID});
      console.log(response,'responseresponseresponse');
      setBranchList(response);
    }
    catch (HTTPException) {
      stopLoader();
      console.error(HTTPException);
      new Toast(HTTPException.message, Toast.TYPE_ERROR, Toast.TIME_LONG);
    }
  }


  return (
    <div class="row">
      <div class="column no-padding">
        <div class='billInvoice'>
          <div class='has-text-center' />
          <div class="container">
            <div class="newlist-table">
              <table >
                <tr>
                  <tbody>
                    <div class="border-black">
                      <div class="no-padding">
                        <div class="row border-bottom-black" style='justify-content: center;'>
                          <h6>Detail of Transportation Bill</h6>
                        </div>
                        <div class="row" style="height:30px" />
                        <div class="">
                          <div class=" no-padding">
                            <div class="row" >
                              {
                              /*
                                 Modified By: Manohar Sule
                                 Modified On: 29 Jan 2024
                                 Modification: When clicked on View Bill icon, check alignment for highlighted part is not correct,
                                 Bug ID:ATC_UI_BG_14
                               */
                              }
                              <div class=" column column-30 no-padding border-right-black" style="border-top:1px solid black; padding-left: 5px !important; text-align: center">
                                <div>{props.bill && props.bill.companyName ? props.bill.companyName : ''}</div>
                                <div>{props.bill && props.bill.branchName ? props.bill.branchName : ''}</div>
                                <div>
                                  <div>{props.bill && props.bill.branchAddress &&  props.bill.branchAddress.line1 ? props.bill.branchAddress.line1 : ''}</div>
                                  <div>{props.bill && props.bill.branchAddress &&  props.bill.branchAddress.city ? props.bill.branchAddress.city : ''}</div>
                                  <div>{props.bill && props.bill.branchAddress &&  props.bill.branchAddress.pincode ? props.bill.branchAddress.pincode : ''}</div>
                                  <div>GST NO- {props.bill && props.bill.branchGST ? props.bill.branchGST : 'NA'}</div>
                                </div>

                              </div>
                              <div class="column column-40 no-padding border-right-black">
                                <div class="row border-top-black">
                                  <div class="column"style="height:118px" />
                                </div>
                                <div class="row">
                                  <div>
                                    <div class="row border-bottom" style="border-top:1px solid black; border-right:1px solid black">
                                      <table style='border-spacing: 0px; '>
                                        <tbody>
                                          <tr style="border-right:1px solid black;">
                                            <td class="p-5" style="border-right:1px solid black; border-bottom:1px solid black">Date</td>
                                            <td class="p-5" style="border-bottom:1px solid black" > {formatDateTime(props.bill.dateOfBill)} </td>
                                          </tr>
                                          <tr style="border-right:1px solid black;">
                                            <td class="p-5" style="border-right:1px solid black">Bill No.</td>
                                            <td class="p-5" style="">{props.bill.billNumber}</td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                                <div />
                              </div>
                              <div class="column column-30 no-padding" style="border-top:1px solid black">
                                <div class="row">
                                  <div class="row border-bottom-black">
                                    <div class="column">
                                      <div style="text-align:center">Bill To</div>
                                    </div>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="column">
                                    <tr>
                                      <td colspan="5" style="text-align:center; width:30rem"><strong>{props.bill.billTo}</strong> <br/> <strong>{props.bill.clientAddress}</strong><br/><strong>GST NO:{props.bill.clientGST}</strong></td>
                                    </tr>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/*
                   Modified By: Arun Singh
                   Modified On: 11 july 2023
                   Modification: Reword - "LR No" instead of "LrNo" ,  space required after r in LrNo (LR No)
                   Bug ID:ATC_BG_53
                  */}
                    <tr class="row border-black border-bottom-none border-top-none text-align-center">
                      <td class="border-right-black w-9vw">LR No</td>
                      <td class="border-right-black w-7vw">Date</td>
                      <td class="border-right-black w-10vw">From</td>
                      <td class="border-right-black w-6vw">Pkg</td>
                      <td class="border-right-black w-6vw">Charged Wt</td>
                      <td class="border-right-black w-6vw">Rate</td>
                      <td class="border-right-black w-6vw">Freight</td>
                      <td class="border-right-black w-6vw">DC</td>
                      <td class="border-right-black w-6vw">DD</td>
                      <td class="border-right-black w-6vw">Other</td>
                      <td class=" w-6vw">Amount</td>
                    </tr>
                    {
                      (props.bill.consignmentDetails.map((row) => (
                        <tr class="row border-black border-bottom-none text-align-center">
                          <td class="border-right-black w-9vw" >{row.consignmentNumber}</td>
                          <td class="border-right-black w-7vw">{formatDateTime(row.bookingDate)}</td>
                          <td class="border-right-black w-10vw">{row.from ? ( row.to ? (row.from + ' - ' + row.to) : row.from) : '-'}</td>
                          <td class="border-right-black w-6vw">{row.numberOfPackages !==0 &&row.numberOfPackages?row.numberOfPackages:'-'}</td>
                          <td class="border-right-black w-6vw">{row.chargedWeight||'-'}</td>
                          <td class="border-right-black w-6vw">{row.basicRate ? (row.basicRate + (row.selectedUnitForRate ? ' per ' +row.selectedUnitForRate : '')): '-'}</td>
                          <td class="border-right-black w-6vw">{row.basicFreightCharges!==0 && row.basicFreightCharges ? getFormattedAmount(row.basicFreightCharges) : '-'}</td>
                          <td class="border-right-black w-6vw">{row.drDDCharges !== 0 && row.drDDCharges ? getFormattedAmount(row.drDDCharges):'-'}</td>
                          <td class="border-right-black w-6vw">{row.ddCharges !== 0 && row.ddCharges ? getFormattedAmount(row.ddCharges):'-'}</td>
                          <td class="border-right-black w-6vw">{row.other ? getFormattedAmount(row.other):'-'}</td>
                          <td class=" w-6vw">{row.totalCharges ? getFormattedAmount(row.totalCharges):'-'}</td>
                        </tr>
                      )))
                    }

                    <tr class="row border-black ">
                      <td class="fs-12 w-50vw border-right-black text-align-center"><strong>Grand Total: {props.bill.amountInWords ? 'RS. ' + props.bill.amountInWords + ' Only' : '-'} </strong></td>
                      <td class="w-5vw text-align-center">{getFormattedAmount(props.bill.grandTotal)}</td>
                    </tr>
                    <tr class='row m-t-15'>
                      <td>GST as applicable to be paid by service receiver under RCM</td>
                    </tr>
                    {
                      /*
                         Modified By: Manohar Sule
                         Modified On: 29 Jan 2024
                         Modification: Top Margin Is missing for "Authorised Signature"
                         Bug ID:ATC_UI_BG_29
                       */
                    }
                    <tr class='row m-t-80'>
                      <td><strong>Authorised Signatory</strong></td>
                      <td class='m-l-auto'><strong>Authorised Signatory</strong></td>
                    </tr>
                    <tr class='row m-t-5'>
                      <td><strong>For Air Transport Corporation (Assam Pvt.ltd)</strong></td>
                      <td class='m-l-auto'><strong>{props.bill.billTo}</strong></td>
                    </tr>


                  </tbody>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

};
export default Bill;
