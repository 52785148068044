import { h } from 'preact';
import { useState, useEffect } from "preact/hooks";
import CONSTANTS from '../../../lib/constants';
import { Toast } from '../../../lib/toastr';
import { startLoader, stopLoader } from '../../../lib/utils';
import { Link } from 'preact-router';
import http from 'fetch-bb';

// export default class ForgotPassword extends Component {
const ForgotPassword = (props) => {

  let [ email, setEmail ] =  useState('');
  let [ errorMessage, setErrorMessage ] =  useState('');
  let [ successMessage, setSuccessMessage ] =  useState('');
  let [ isResponseReceived, setIsResponseReceived ] =  useState(false);
  let [ isSendButtonDisabled, setIsSendButtonDisabled ] =  useState(false);

  async function sendEmail(e) {
    e.preventDefault();
    // this.setState({
    //   email: e.target.email.value,
    //   isResponseReceived:true,
    //   errorMessage: '',
    //   successMessage: ''
    // });

    setEmail(e.target.email.value);
    setIsResponseReceived(true);
    setErrorMessage('');
    setSuccessMessage('');

    try {
      startLoader();
      await http.post(`${CONSTANTS.API_URL}/api/admin/forgotpassword`, {
        email: e.target.email.value
      });
      //this.setState({successMessage: 'Please check your Inbox for further instructions'});
      // this.setState({errorMessage: ''});
      // this.state.isSendButtonDisabled = true;
      // this.setState({
      //   isResponseReceived:false
      // });
      setSuccessMessage('Please check your Inbox for further instructions');
      setErrorMessage('');
      setIsSendButtonDisabled(true);
      setIsResponseReceived(false);
      stopLoader();
    }
    catch (HTTPException) {
      console.error(HTTPException);
      new Toast(HTTPException.message, Toast.TYPE_ERROR, Toast.TIME_NORMAL);
      stopLoader();
      if (HTTPException.statusCode === 404)
      //   this.setState({errorMessage: 'Registered user not found'});
      // this.setState({successMessage: ''});
      // this.setState({
      //   isResponseReceived:false
      // });
        setErrorMessage('Registered user not found');
      setSuccessMessage('');
      setIsResponseReceived(false);
      stopLoader();
      return false;
    }
  }

  // componentWillMount () {
  //   this.state = {
  //     email: '',
  //     errorMessage: '',
  //     successMessage: '',
  //     isResponseReceived: false,
  //     isSendButtonDisabled: false
  //   };
  // }

  // componentDidMount() {
  // }

  // render() {
  return (
    <section class="row-center main-login-section">
      <div class="limiter auth-center">
        <div class="container-login100">
          <div class="wrap-login100">

            <form class="login100-form validate-form" onSubmit={e => sendEmail(e)}>
              <img src="../assets/static/atc.jpg" style="width:200px"/>
              <br/>
              <span style="display:inline-block;margin:10px 0;font-size:15px">
                  Please enter your registered Email ID
              </span>
              <div class="wrap-input100 validate-input">
                <input class="input100" value={email} id="email" type="email" autofocus name="email" maxlength="75" placeholder="Email"
                  disabled={isResponseReceived} required />
                <span class="focus-input100"/>
                <span class="symbol-input100" style="position: absolute;left: 75%;">
                  <i class="fa fa-envelope"/>
                </span>
              </div>

              <div class="container-login100-form-btn">
                <button disabled={isSendButtonDisabled} class="login100-form-btn">
                    SEND
                </button>
              </div>

              <div class="text-center p-t-12">
                {
                  successMessage &&
                    <span>{successMessage}</span>
                }
                {
                  errorMessage &&
                    <span>{errorMessage}</span>
                }
              </div>

              <div class="text-center p-t-12">
                <Link class="hyperlink" href="/admin/login" style="color:#ad0b0b!important">Back to Login</Link>
              </div>
            </form>
          </div>
        </div>
      </div>

    </section>
  );
  // }
};

export default ForgotPassword;
