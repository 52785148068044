import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';
import {route} from 'preact-router';
import { Toast } from '../../../lib/toastr';
import CONSTANTS from '../../../lib/constants';
import { AppStore } from '../../../lib/store';
import {passwordErrorMessageText} from '../../../lib/utils';
import http from 'fetch-bb';

let changePasswordToken;

const changePassword = (props) => {
  let [ isVerifyOldPassword, setIsVerifyOldPassword ] = useState(false);
  let [ oldPassword, setOldPassword ] = useState('');
  let [ isVerifyResponseReceived, setIsVerifyResponseReceived ] = useState(false);
  let [ isNextButtonClicked, setIsNextButtonClicked ] = useState(false);

  let [ otype, setOtype ] = useState('password');
  let [ ntype, setNtype ] = useState('password');
  let [ score, setScore ] = useState('null');
  let [ isResponseReceived, setIsResponseReceived ] = useState(false);
  let [ isButtonClicked, setIsButtonClicked ] = useState(false);
  let [ newpassword, setNewpassword ] = useState('');
  let [ confirmNewPassword, setConfirmNewPassword ] = useState('');
  let [ errorMessage, setErrorMessage ] = useState('');
  let [ successMessage, setSuccessMessage ] = useState('');
  let [ verifyOldPasswordError, setVerifyOldPasswordError ] = useState('');
  let [passwordErrorMessage, setPasswordErrorMessage] = useState('Password must contain atleast one');
  const regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,20}$/;
  const [isPasswordSame, setIsPasswordSame] = useState(true);
  const [isPasswordValid, setIsPasswordValid] = useState(true);

  function verifyOldPassword(e) {
    e.preventDefault();
    setOldPassword(e.target.oldPassword.value);
    setIsVerifyResponseReceived(true);
    setIsNextButtonClicked(true);

    return http.post(`${CONSTANTS.API_URL}/api/admin/user/verifyOldPassword`,{
      oldPassword: e.target.oldPassword.value
    })
      .then(() => {
        setIsVerifyResponseReceived(false);
        setIsNextButtonClicked(false);
        setIsVerifyOldPassword(true);
        setVerifyOldPasswordError('');
        // return route('/admin/changePassword', true);
      })
      .catch((DBException) => {
        setIsVerifyResponseReceived(false);
        setIsNextButtonClicked(false);
        setIsVerifyOldPassword(false);
        setVerifyOldPasswordError(DBException.message);
        new Toast(DBException.message, Toast.TYPE_ERROR, Toast.TIME_LONG);
        console.error(DBException);
      });
  }

  function showHide(e) {
    e.preventDefault();
    // e.stopPropagation();
    otype === 'text' ? setOtype('password') : setOtype('text');
  }

  function showHideN(e) {
    e.preventDefault();
    // e.stopPropagation();
    ntype === 'text' ? setNtype('password') : setNtype('text');
  }

  function validateThePasswordCriterion (e) {
    setIsPasswordValid(false);
    setIsPasswordValid(regex.test(e.target.value));
    let passwordError = passwordErrorMessageText(e.target.value);
    console.log(passwordError,'passwordError');
    if (passwordError){
      setPasswordErrorMessage(passwordError);
    }
    else {
      setIsPasswordValid(true);
    }
  }

  function validatePasswordSameCriterion (e) {
    if (e.target.value){
      setIsPasswordSame(newpassword === e.target.value);
    }
    else {
      setIsPasswordSame(true);
    }
  }


  function changePassword(e) {
    e.preventDefault();
    if (isButtonClicked || !isPasswordValid || !isPasswordSame || !newpassword || !confirmNewPassword) {
      return;
    }
    setIsResponseReceived(true);
    setNewpassword(e.target.newPass.value);
    setConfirmNewPassword(e.target.confirmPass.value);
    setIsButtonClicked(true);

    // let newpassword = e.target.newPass.value;
    // let confirmNewPassword = e.target.confirmPass.value;

    http.put(`${CONSTANTS.API_URL}/api/admin/user/changepassword`,{
      newPassword: newpassword,
      confirmpassword: confirmNewPassword
    })
      .then(() => {
        new Toast('Password changed successfully, login again with new password', Toast.TYPE_DONE, Toast.TIME_LONG);
        setErrorMessage('');
        logout();
      })
      .catch((HTTPException) => {
        if (HTTPException.statusCode === 409) {
          setIsResponseReceived(false);
          setIsButtonClicked(false);
          new Toast('Passwords do not match', Toast.TYPE_ERROR, Toast.TIME_LONG);
          setErrorMessage('Passwords do not match');
          setSuccessMessage('');
          return '';
        }
        else if (HTTPException.statusCode === 400 || HTTPException.statusCode === 417) {
          setIsResponseReceived(false);
          setIsButtonClicked(false);
          new Toast('Passwords must contain At least 6 characters, including UPPERCASE, lowercase letters, '+
           'special characters and Digits', Toast.TYPE_ERROR, Toast.TIME_LONG);
          setErrorMessage('Passwords must contain At least 6 characters, including UPPERCASE, lowercase letters, special characters and Digits');
          setSuccessMessage('');
          return '';
        }
        setIsResponseReceived(false);
        setIsButtonClicked(false);
      });
  }

  function logout() {
    http.post(`${CONSTANTS.API_URL}/api/admin/logout`)
      .then(() => {
        AppStore.removeAll();
        new Toast('Successfully logged out', Toast.TYPE_DONE, Toast.TIME_LONG);
        route(`/admin/login`);
      })
      .catch((HTTPException) => {
        console.error(HTTPException);
      });
  }

  function goBack() {
    // return history.go(-2);
    setIsVerifyOldPassword(false);
    setOldPassword('');
    setIsVerifyResponseReceived(false);
    setIsNextButtonClicked(false);
    setVerifyOldPasswordError('');
  }

  // useEffect(() => {
  //   changePasswordToken = props.matches.changePasswordToken;
  //   if (!changePasswordToken) {
  //     route('/admin/changePassword');
  //   }
  // }, [props]);

  return (
    <div id="main-body" class="body-wrapper center-div h-76vh">
      {
        !isVerifyOldPassword && (
          <div class="w-32vw">
            <p class="has-text-left">To continue, first verify its you.</p>
            <br />
            <form class="box" onSubmit={e => verifyOldPassword(e)}>
              <div class="row">
                <div class="column auth-form">
                  <div class="field">
                    <input value={oldPassword} name="oldPassword" id="oldPassword" type="password" placeholder="Enter your old password"
                      disabled={isVerifyResponseReceived} required/>
                  </div>
                  <button class="auth-button" style="width:100px;" type="submit" disabled={isNextButtonClicked}>Next</button>
                  {
                    verifyOldPasswordError && (
                      <div class='m-t-10'>
                        <p class='text-error'> {verifyOldPasswordError} </p>
                      </div>
                    )
                  }

                </div>
              </div>
            </form>
          </div>
        )
      }
      {
        isVerifyOldPassword && (
          <div class="auth-center w-32vw">
            <form class="box" onSubmit={e => changePassword(e)}>
              <div class="row">
                <div class="column">
                  <h6 class="inline">Confirm Password</h6>
                </div>
              </div>
              <div class='row'>
                {
                  !isPasswordValid && (
                    <div class='m-t-10 m-b-10 text-error' style='padding-left: 0.5rem'>
                      {passwordErrorMessage}
                    </div>
                  )
                }
                {
                  !isPasswordSame && (
                    <div class='m-t-10 m-b-10 text-error' style='padding-left: 0.5rem'>
                          Please enter same password as above
                    </div>
                  )
                }
              </div>
              <div class="row">
                <div class="column auth-form" style="position:relative;">
                  <div class="row field">
                    <input id="txtpassword" name="newPass" placeholder="Enter New Password" onInput={(e) => {
                    // handleChange(e);
                      setNewpassword(e.target.value);
                      validateThePasswordCriterion(e);
                      if (confirmNewPassword && confirmNewPassword.length) {
                        setIsPasswordSame(e.target.value === confirmNewPassword);
                      }
                    }}
                      // onInput={e => setNewpassword(e.target.value)}
                    type={otype}  disabled={isResponseReceived} value={newpassword} required/>
                    {/*<label for="txtpassword" class="has-text-left" style="font-size:0.8em">Enter New Password</label>*/}
                    <span style="position: absolute; font-size: 1.6rem; right: 26px; top: 50px; cursor: pointer" onClick={e => showHide(e)}>
                      <i class={otype === 'text' ? 'icon-eye' : 'icon-eye-disabled'}/>
                    </span>
                  </div>
                  <div class="row field">
                    <input id="confirmPass" name="confirmPass" type={ntype} placeholder="Confirm New Password" value={confirmNewPassword}
                      disabled={isResponseReceived} onInput={(e) => {
                        setConfirmNewPassword(e.target.value);
                        // handleChange(e);
                        validatePasswordSameCriterion(e);
                      }}
                      // onInput={e => setConfirmNewPassword(e.target.value)}
                      required/>
                    {/*<label for="confirmPass" class="has-text-left" style="font-size:0.8em">Confirm New Password</label>*/}
                    <span onClick={e => showHideN(e)} style="position: absolute; font-size: 1.6rem; right: 26px; top: 115px; cursor: pointer">
                      <i class={ntype === 'text' ? 'icon-eye' : 'icon-eye-disabled'}/>
                    </span>
                  </div>
                  {/*
                      BY: Prasannadatta Kawadkar
                      ON: 12 Feb 2024
                      Modification: the error msg is shown in toastr this was not needed.
                    */}
                  {/*errorMessage && (
                      <div id="error" class="error-color">
                        <span>
                          {errorMessage}

                        </span>
                      </div>
                    )}
                    {successMessage && (
                      <div>
                        <span>{successMessage}

                        </span>
                      </div>
                    )*/}
                  <div class="row">
                    <div>
                      <button type="button" onClick={e => goBack(e)} id="cancelButton" class="is-fullwidth auth-button">Back</button>
                    </div>
                    <div class="m-l-8">
                      <button type="submit"  disabled={isButtonClicked || !(isPasswordValid && isPasswordSame && newpassword && confirmNewPassword)} id="confirmButton" class="is-fullwidth auth-button">Submit</button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <p>  Choose a strong password and do not reuse it for other accounts..</p>
          </div>
        )
      }

    </div>
  );

};

export default changePassword;
