import { h } from 'preact';
import { useState, useEffect } from "preact/hooks";
import { Modal, ModalBody, ModalFooter } from '../../../components/modal';
import CONSTANTS from '../../../lib/constants';
import http from 'fetch-bb';
import { Toast } from '../../../lib/toastr';
// import linkState from 'linkstate';
import { startLoader, stopLoader } from '../../../lib/utils';
import Pagination from '../../../components/pagination';
import { Pagination1 } from "../../../components/pagination1";
import { route } from 'preact-router';
import { ViewDetails, Edit } from '../../../components/svgs';

// export default class Companies extends Component {
const Companies = (props) => {
  // componentWillMount() {
  //   this.state = {
  //     isAddCompanyModalOpen: false,
  //     company: {
  //       address: {}
  //     },
  //     companies: [],
  //     isEditCompany: false,
  //     modalTitle: 'Add Company',
  //     companyObj: {}  //Cloned object for edit
  //   };
  // }
  let [ companyData, setCompanyData ] = useState({});
  let [ company, setCompany ] = useState({address: {}});
  let [ companies, setCompanies ] = useState([]);
  let [ isEditCompany, setIsEditCompany ] = useState(false);
  let [ modalTitle, setModalTitle ] = useState('Add Company');
  let [ companyObj, setCompanyObj ] = useState({});
  let [ isAddCompanyModalOpen, setIsAddCompanyModalOpen ] = useState(false);
  let [ address, setAddress ] = useState({});
  let [ totalPages, setTotalPages] = useState(0);
  let [pageSize, setPageSize] = useState(50);
  let [ currentPageNo, setCurrentPageNo ] = useState(1);
  let [goingLeft, setGoingLeft] = useState(false);
  let [dynamicColumnsArray, setDynamicColumnsArray] = useState([]);
  let [totalCount, setTotalCount] = useState(0);



  useEffect(() => {
    setCompanyData({
      "line1": "",
      "line2": "",
      "city": "",
      "state": "",
      "pincode": "",
      "status": "",
      "displayName": "",
      "shortName": "",
      "name": "",
      "contactName": "",
      "contactPhone": "",
      "contactEmail": "",
      "subscriptionStatus": "",
      "pan": "",
      "gst": ""
    })
  },[])

  useEffect(() => {
    getCompanies();
    getComponiesCount();
  }, [currentPageNo, pageSize]);

  async function getCompanies() {
    try {
      startLoader();
      let companies = await http.get(`${CONSTANTS.API_URL}/api/company?pageNo=${currentPageNo}&pageSize=${pageSize}`);
      stopLoader();
      // this.setState({companies});
      setCompanies(companies);
    }
    catch (HTTPException) {
      stopLoader();
      console.error(HTTPException);
      new Toast(HTTPException.message, Toast.TYPE_ERROR, Toast.TIME_LONG);
    }
  }

  function getComponiesCount() {
    return http
      .get(`${CONSTANTS.API_URL}/api/company/count`)
      .then((count) => {
        setTotalPages(count.count);
        setTotalCount(count.count)
        // this.setState({ totalPages:count.count });
      })
      .catch((HTTPException) => {
        console.error(HTTPException);
      });
  }

  async function createOrEditCompany(e) {
    e.preventDefault();
    // let body = {
    //   displayName: e.target.displayName.value,
    //   name: e.target.name.value,
    //   shortName: e.target.shortName.value,
    //   line1: e.target.line1.value,
    //   line2: e.target.line2.value,
    //   city: e.target.city.value,
    //   state: e.target.state.value,
    //   pincode: e.target.pincode.value,
    //   status: e.target.status.value,
    //   contactName: e.target.contactName.value,
    //   contactPhone: e.target.contactPhone.value,
    //   contactEmail: e.target.contactEmail.value,
    //   subscriptionStatus: e.target.subscriptionStatus.value,
    //   pan: e.target.pan.value,
    //   gst: e.target.gst.value
    // };
    // console.log(body,'===============', companyData);
    // return;
    try {
      startLoader();
      let url = isEditCompany ? `${CONSTANTS.API_URL}/api/company/${companyObj.uuid}` : `${CONSTANTS.API_URL}/api/company`;
      let method = isEditCompany ? 'put' : 'post';
      await http[method](url, companyData);
      toggleAddCompany('close');
      await getCompanies();
    } catch (HTTPException) {
      stopLoader();
      console.error(HTTPException);
      new Toast(HTTPException.message, Toast.TYPE_ERROR, Toast.TIME_LONG);
    }
  }

  // componentDidMount() {
  //   // if ( document.getElementById("gn-menu-nav").classList.contains('gn-open-all') ) {
  //   //   document.getElementById("main-body").classList.remove('margin-left-76');
  //   //   document.getElementById("main-body").classList.add('margin-left-266');
  //   // } else {
  //   //   document.getElementById("main-body").classList.remove('margin-left-266');
  //   //   document.getElementById("main-body").classList.add('margin-left-76');
  //   // }
  //   this.getCompanies();
  // }

  function handleXScroll(event) {
    const currentScrollY = event.currentTarget.scrollTop;
    // console.log(event.currentTarget)
    if (event.currentTarget.scrollLeft >= 170) {
      setGoingLeft(true);
    } else {
      setGoingLeft(false);
    }
  }

  function toggleAddCompany(action, row) {
    if (action === 'add') {
      // this.setState({isAddCompanyModalOpen: !this.state.isAddCompanyModalOpen, isEditCompany: false, modalTitle: 'Add Company', companyObj: {address: {}}});
      setIsAddCompanyModalOpen(!isAddCompanyModalOpen);
      setIsEditCompany(false);
      setModalTitle('Add Company');
      setCompanyObj({address: {}});
    }
    if (action === 'edit') {
      // this.setState({isAddCompanyModalOpen: !this.state.isAddCompanyModalOpen, isEditCompany: true, modalTitle: 'Edit Company', companyObj: Object.assign({}, row)});
      setIsAddCompanyModalOpen(!isAddCompanyModalOpen);
      setIsEditCompany(true);
      setModalTitle('Edit Company');
      let data = {
        "line1": row.address && row.address.line1 ? row.address.line1 : '',
        "line2": row.address && row.address.line2 ? row.address.line2 : '',
        "city": row.address && row.address.city ? row.address.city : '',
        "state": row.address && row.address.state ? row.address.state : '',
        "pincode": row.address && row.address.pincode ? row.address.pincode : '',
        "status": row.status ? row.status : '',
        "displayName": row.displayName ? row.displayName : '',
        "shortName": row.shortName ? row.shortName : '',
        "name": row.name ? row.name : '',
        "contactName": row.contactName ? row.contactName : '',
        "contactPhone": row.contactPhone ? row.contactPhone : '',
        "contactEmail": row.contactEmail ? row.contactEmail : '',
        "subscriptionStatus": row.subscriptionStatus ? row.subscriptionStatus : '',
        "pan": row.pan ? row.pan : '',
        "gst": row.gst ? row.gst : ''
      }
      setCompanyObj(Object.assign({}, row));
      setCompanyData(Object.assign({}, data));

    }
    if (action === 'close') {
      // this.setState({isAddCompanyModalOpen: !this.state.isAddCompanyModalOpen, isEditCompany: false, company: {address: {}}, companyObj: {address: {}}});
      setIsAddCompanyModalOpen(!isAddCompanyModalOpen);
      setIsEditCompany(false);
      setCompany({address: {}});
      setCompanyObj({address: {}});
      setCompanyData({});
    }
  }

  function goToDetails(company) {
    console.log(company,'companycompany');
    route(`/company/${company.uuid}`);
  }

  async function onPageSizeChange(newPageSize) {
    // pageSize = newPageSize;
    if (totalCount > 0 && currentPageNo > Math.ceil(totalCount / newPageSize)) {
      currentPageNo = Math.ceil(totalCount / newPageSize);
      await setCurrentPageNo(currentPageNo);
    }
    await setPageSize(pageSize);
    // getCompanies();
  }
  async function onChangePageClick(pageNo) {
    // currentPageNo = pageNo;
    await setCurrentPageNo(pageNo);
    // getCompanies();
  }

  // render({}, state) {

    return (
      <div>
        {/*<SideBar1 activeMenu={'/clients'}/>*/}
          <div class="body-wrapper">
            <div class="row">
              <div class="main-heading sticky body-60">Companies</div>
              <div class="voucher-buttons body-40 p-l-20">
                <button
                  type="button"
                  class="btn1 m-0"
                  onClick={() => toggleAddCompany('add')}
                >
                  ADD COMPANY
                </button>
              </div>
            </div>
            <div class="row outer1 f-w-100 bgwhite p-16" style="height: calc(100vh - 80px)">
              <div
                onScroll={handleXScroll}
                class="no-scrollbar col-xs-12 overflow-x-y col-sm-12 col-md-12 col-lg-12 p-r-0 p-l-0"
                style="height: calc(100vh - 65px)"
              >
                <div>
                  <div class="row" style='overflow: auto; height: calc(100vh - 130px);'>
                    <table class="m-b-24 m-l-r-8">
                      <thead class="listcard-thead">
                        <tr class="pos-sticky z-index-1">
                          <th
                            style="width: 13rem; text-align: left; padding: 5px !important"
                            title="Name"
                            class={`p-t-8 stickyTableHead  `}
                          >
                            <span class="pos-relative">
                              {" "}
                              Name

                            </span>
                          </th>

                          <th
                            style="width: 11rem; text-align: left; padding: 5px !important"
                            title="type"
                            class={`p-t-8 `}
                          >
                            <span class="pos-relative">
                              {" "}
                              Short Name

                            </span>
                          </th>
                          <th
                            style="width: 11rem;  text-align: left"
                            title="city"
                            class={`p-t-8 `}
                          >
                            <span class="pos-relative">
                              {" "}
                              Status

                            </span>
                          </th>
                          <th
                            style="width: 11rem; text-align: left; padding: 5px !important"
                            title="state"
                            class={`p-t-8 `}
                          >
                            <span class="pos-relative">
                              {" "}
                              Contact Name

                            </span>
                          </th>
                          <th
                            style="width: 11rem; text-align: left; padding: 5px !important"
                            title="outstandingAmount"
                            class={`p-t-8 `}
                          >
                            <span class="pos-relative">
                              {" "}
                              Contact Number

                            </span>
                          </th>
                          <th
                            style="width: 11rem; text-align: left; padding: 5px !important"
                            title="outstandingAmount"
                            class={`p-t-8 `}
                          >
                            <span class="pos-relative">
                              {" "}
                              Subscription Status
                            </span>
                          </th>
                          <th
                            style="width: 11rem; text-align: left; padding: 5px !important"
                            title="action"
                            class={`p-t-8 `}
                          >
                            <span class="pos-relative">
                              {" "}
                              Action
                            </span>
                          </th>
                        </tr>
                        <tr
                          style={`width: calc(88vw + ${
                            dynamicColumnsArray.length * 108
                          }px)`}
                          class="pos-absolute top-0 bottom-neg-3 left-0 bgwhite  bor-b-2"
                        />
                      </thead>
                      <tbody class="listcard1-container">
                      { companies && companies.map ((row, rowIndex) => (
                        <tr class="listcard1-container bor-b p-0  grouped-items-accordion z-index-2 display-flex" style="width: 100vw">
                          <td style="width: 13rem; text-align: left; padding: 5px !important;" class={`${goingLeft ? " stickyTableCell " : " "} fadeMoveUpAnimationNotForwards pos-relative p-0 `} >

                          <span class="h-full flex-l-m" title="Name">{row.displayName}
                          </span>
                          </td>
                          <td style="width: 11rem; padding: 5px !important;" class=" cursor-default  fadeMoveUpAnimationNotForwards  flex-l-m ">
                            <span>{row.shortName}</span>
                          </td>
                          <td style="width: 11rem; padding: 5px !important;" class=" cursor-default  fadeMoveUpAnimationNotForwards  flex-l-m ">
                            <span>{row.status ? row.status.toUpperCase() : '-'}</span>
                          </td>
                          <td style="width: 11rem; padding: 5px !important;" class=" cursor-default  fadeMoveUpAnimationNotForwards  flex-l-m ">
                            <span>{row.contactName}</span>
                          </td>
                          <td style="width: 11rem; padding: 5px !important;" class=" cursor-default  fadeMoveUpAnimationNotForwards  flex-l-m ">
                            <span>{row.contactPhone}</span>
                          </td>
                          <td style="width: 11rem; padding: 5px !important;" class=" cursor-default  fadeMoveUpAnimationNotForwards  flex-l-m ">
                            <span>{row.subscriptionStatus ? row.subscriptionStatus.toUpperCase() : '-'}</span>
                          </td>
                          <td style="width: 10rem; justify-content: start; margin-left: 1rem" class=" cursor-default  fadeMoveUpAnimationNotForwards  flex-c-m ">
                            <span class="m-l-4"><button class="btn4" onClick={() => goToDetails(row)} ><ViewDetails/></button></span>
                            <span class="m-l-4"><button class="btn4" onClick={() => toggleAddCompany('edit', row)} ><Edit/></button></span>
                          </td>

                        </tr>
                      ))}

                      </tbody>
                    </table>

                  </div>
                </div>
                <div class="listcard1-pagination">
                  <Pagination1
                    classes={false}
                    listData={companies}
                    count={totalPages}
                    currentPageNo={currentPageNo}
                    currentPageSize={pageSize}
                    onPageSizeChange={(e) => onPageSizeChange(e)}
                    onChangePageClick={(e) => onChangePageClick(e)}
                  />
                </div>
              </div>
            </div>

          {/*<div class="box">
            <table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Short Name</th>
                  <th>Status</th>
                  <th>Contact Name</th>
                  <th>Contact Number</th>
                  <th>Subscription Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {
                  companies.map((row) => (

                    <tr>
                      <td>{row.displayName}</td>
                      <td>{row.shortName}</td>
                      <td>{row.status}</td>
                      <td>{row.contactName}</td>
                      <td>{row.contactPhone}</td>
                      <td>{row.subscriptionStatus}</td>
                      <td>
                        <button type="button" onClick={() => toggleAddCompany('edit', row)}>Edit</button>
                        <button type="button" onClick={() => goToDetails()}>View Details</button>
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
            <div class="has-text-right column no-padding pagination">
              <Pagination count={totalPages} currentPageNo={currentPageNo} />
            </div>
          </div>*/}
          {
            isAddCompanyModalOpen && (
              <Modal title={modalTitle} modalSize="is-medium" onClose={() => toggleAddCompany('close')}>
                <form name="addOrEditCompany" onSubmit={(e) => createOrEditCompany(e)}>
                  <ModalBody>
                    <div class="row">
                      <div class="column">
                        <label style="margin-bottom:5px">Company Information</label>
                      </div>
                    </div>
                    <div class="row">
                      <div class="column">
                        <label>Name <sup style="color:red">*</sup></label>
                        <input defaultValue={companyData.name} type="text" onInput={e => setCompanyData(existingValues => ({...existingValues, name: e.target.value}))}
                          required placeholder="Enter Name" name="name" required />
                      </div>
                      <div class="column">
                        <label>Display name <sup style="color:red">*</sup></label>
                        <input defaultValue={companyData.displayName} type="text" onInput={e => setCompanyData(existingValues => ({...existingValues, displayName: e.target.value}))}
                          required placeholder="Enter Display Name" name="displayName" required/>
                      </div>
                    </div>
                    <div class="row">
                      <div class="column column-50 float-left">
                        <label>Short Name <sup style="color:red">*</sup></label>
                        <input defaultValue={companyData.shortName} type="text" onInput={e => setCompanyData(existingValues => ({...existingValues, shortName: e.target.value}))}
                          required placeholder="Enter Short Name" name="shortName" />
                      </div>
                    </div>
                    <div class="row">
                      <div class="column">
                        <label>Status <sup style="color:red">*</sup></label>
                        <select required name="status" onInput={e => setCompanyData(existingValues => ({...existingValues, status: e.target.value}))}>
                          <option value='active' selected={companyObj.status === 'active'}>Active</option>
                          <option value='disabled' selected={companyObj.status === 'disabled'}>Disable</option>
                          <option value='live' selected={companyObj.status === 'live'}>Live</option>
                          <option value='demo' selected={companyObj.status === 'demo'}>Demo</option>
                          <option value='suspended' selected={companyObj.status === 'suspended'}>Suspended</option>
                        </select>
                      </div>
                      <div class="column">
                        <label>Subscription Status</label>
                        <select name="subscriptionStatus" onInput={e => setCompanyData(existingValues => ({...existingValues, subscriptionStatus: e.target.value}))}>
                          <option selected={companyObj.subscriptionStatus === 'active'} value='active'>Active</option>
                          <option selected={companyObj.subscriptionStatus === 'disabled'} value='disabled'>Disable</option>
                        </select>
                      </div>
                    </div>
                    <div class="row">
                      <div class="column">
                        <label>PAN No. <sup style="color:red">*</sup></label>
                        <input defaultValue={companyData.pan} pattern="[A-Z]{5}[0-9]{4}[A-Z]" onInput={e => setCompanyData(existingValues => ({...existingValues, pan: e.target.value}))} type="text"
                          placeholder="Enter PAN Number" name="pan" required/>
                      </div>
                      <div class="column">
                        <label>GST No. <sup style="color:red">*</sup></label>
                        <input defaultValue={companyData.gst} pattern="[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}"
                        maxlength='15'
                        minlength='15' onInput={e => setCompanyData(existingValues => ({...existingValues, gst: e.target.value}))} type="text"
                          placeholder="Enter GST Number" name="gst" required/>
                      </div>
                    </div>
                    <hr/>
                    <div class="row">
                      <div class="column">
                        <label style="margin-bottom:5px">Address Information</label>
                      </div>
                    </div>
                    <div class="row">
                      <div class="column">
                        <label>Address Line 1 <sup style="color:red">*</sup></label>
                        <input defaultValue={companyData.line1} onInput={e => setCompanyData(existingValues => ({...existingValues, line1: e.target.value}))} type="text" placeholder="Line 1" name="line1" required />
                      </div>
                      <div class="column">
                        <label>Address Line 2</label>
                        <input defaultValue={companyData.line2} onInput={e => setCompanyData(existingValues => ({...existingValues, line2: e.target.value}))} type="text" placeholder="Line 2" name="line2" />
                      </div>
                    </div>
                    <div class="row">
                      <div class="column">
                        <label>Pincode <sup style="color:red">*</sup></label>
                        <input defaultValue={companyData.pincode} pattern="[1-9][0-9]{2}\s?[0-9]{3}" onInput={e => setCompanyData(existingValues => ({...existingValues, pincode: e.target.value}))} type="text"
                          placeholder="Pincode" name="pincode" required/>
                      </div>
                      <div class="column">
                        <label>City <sup style="color:red">*</sup></label>
                        <input defaultValue={companyData.city} onInput={e => setCompanyData(existingValues => ({...existingValues, city: e.target.value}))} type="text" placeholder="City" name="city" required/>
                      </div>
                    </div>
                    <div class="row">
                      <div class="column column-50 float-left">
                        <label>State <sup style="color:red">*</sup></label>
                        <input defaultValue={companyData.state} onInput={e => setCompanyData(existingValues => ({...existingValues, state: e.target.value}))} type="text" placeholder="State" name="state" required/>
                      </div>
                    </div>
                    <hr/>
                    <div class="row">
                      <div class="column">
                        <label style="margin-bottom:5px">Contact Information</label>
                      </div>
                    </div>
                    <div class="row">
                      <div class="column">
                        <label>Name <sup style="color:red">*</sup></label>
                        <input defaultValue={companyData.contactName} onInput={e => setCompanyData(existingValues => ({...existingValues, contactName: e.target.value}))} type="text" placeholder="Enter Name" name="contactName" required/>
                      </div>
                      <div class="column">
                        <label>Mobile <sup style="color:red">*</sup></label>
                        <input defaultValue={companyData.contactPhone} pattern="[789]\d{9}" onInput={e => setCompanyData(existingValues => ({...existingValues, contactPhone: e.target.value}))} type="text"
                          placeholder="Enter Mobile" name="contactPhone" maxlength="10" minlength="10" required/>
                      </div>
                    </div>
                    <div class="row">
                      <div class="column column-50 float-left">
                        <label>Email</label>
                        <input defaultValue={companyData.contactEmail} onInput={e => setCompanyData(existingValues => ({...existingValues, contactEmail: e.target.value}))} type="email"
                          placeholder="Enter Email" name="contactEmail" />
                      </div>
                    </div>
                  </ModalBody>
                  <ModalFooter>
                    <button type="reset" class="button-clear" onClick={() => toggleAddCompany('close')}>Cancel</button>
                    <button>Save</button>
                  </ModalFooter>
                </form>
              </Modal>)
          }
        </div>
      </div>
    );
  // }
}

export default Companies;
